
import { defineComponent, toRefs, reactive, ref, onMounted } from 'vue';
import useAppeal from '@/hooks/communication/useAppeal';
import AppealTable from '../components/AppealTable.vue';
import moment, { Moment } from 'moment';
import { message } from 'ant-design-vue';
import { CalendarOutlined } from '@ant-design/icons-vue';
import {
    findByGroupCode,
    communicationHistoryData,
} from '@/API/communication/appeal';
import downloadFile from '@/utils/claim/downloadFile';
import { onBeforeRouteLeave } from 'vue-router';

export default defineComponent({
    components: { AppealTable, CalendarOutlined },
    beforeRouteEnter(to, from, next) {
        next((vm: any) => {
            if (from.fullPath === '/communication/appeal/detail') {
                const historySearchCondition = window.sessionStorage.getItem(
                    'historySearchCondition'
                );
                if (historySearchCondition !== null) {
                    const searchCondition = JSON.parse(historySearchCondition);
                    vm.vinNos = searchCondition.vins;
                    if (searchCondition.vins.length === 0) {
                        vm.vinNo = searchCondition.vin;
                    } else {
                        vm.vinNo = searchCondition.vins[0];
                    }
                    vm.model = searchCondition.modelName;
                    vm.modelYear = searchCondition.modelYear;
                    vm.submissionDate = [
                        searchCondition.claimDateStart === ''
                            ? ''
                            : moment(searchCondition.claimDateStart),
                        searchCondition.claimDateEnd === ''
                            ? ''
                            : moment(searchCondition.claimDateEnd),
                    ];
                    vm.communicationDeadline = [
                        searchCondition.confirmDeadlineStart === ''
                            ? ''
                            : moment(searchCondition.confirmDeadlineStart),
                        searchCondition.confirmDeadlineEnd === ''
                            ? ''
                            : moment(searchCondition.confirmDeadlineEnd),
                    ];
                    vm.programCode = searchCondition.programCode;
                    vm.programNameCn = searchCondition.programNameCn;
                    vm.checkResult = searchCondition.checkResult;
                    vm.communicationStatus =
                        searchCondition.communicationStatus;
                    vm.appealStatus = searchCondition.appealStatus;
                    vm.queryDealerTrade = searchCondition.queryDealerTrade;
                    vm.applicationStatus = searchCondition.applicationStatus;
                }
            }
        });
    },
    setup() {
        const {
            spaceSize,
            batchPlaceholder,
            textContentNo,
            input,
            clearBatchQuery,
            batchQuery,
            batchQueryEvent,
            textNoArray,
            closeBatchQueryModal,
            textChangEvent,
            vinNo,
            vinNos,
            model,
            modelYear,
            submissionDate,
            programCode,
            programName,
            checkResult,
            checkResultList,
            communicationStatus,
            communicationStatusList,
            appealStatus,
            queryDealerTrade,
            appealStatusList,
            modelYearList,
            reset,
            init,
            dealerCode,
            buList,
        } = useAppeal();

        const communicationDeadline = ref<Moment[]>([]);
        const genCommunicationDeadline = () => {
            const currentDate = new Date();
            const oneYearAgo = new Date().setFullYear(
                new Date().getFullYear() - 1
            );
            const tempDate = new Date(oneYearAgo);
            const oneYearAgo2 = tempDate.setDate(tempDate.getDate() + 1);
            communicationDeadline.value[0] = moment(oneYearAgo2);
            communicationDeadline.value[1] = moment(currentDate);
        };
        genCommunicationDeadline();

        const rangePickerCheck = () => {
            const ONE_YEAR = 24 * 60 * 60 * 1000 * 365;
            const gap =
                Date.parse(
                    communicationDeadline.value[1].format('YYYY/MM/DD')
                ) -
                Date.parse(communicationDeadline.value[0].format('YYYY/MM/DD'));
            if (gap - ONE_YEAR > 0) {
                message.error('所选择的沟通截止日期范围不能超过一年');
                communicationDeadline.value = [];
            }
        };

        const applicationStatus = ref<string | undefined>(undefined);
        const applicationStatusList = ref<any[]>([]);
        const applicationMap = ref<any>({});
        const getApplicationStatus = () => {
            findByGroupCode({ groupCode: 'dealer_display_status_drop' }).then(
                (res: any) => {
                    res.forEach((item: any) => {
                        applicationMap.value[item.id] = item.itemCodes;
                    });
                    applicationStatusList.value = res;
                }
            );
        };

        const historyReset = () => {
            reset();
            communicationDeadline.value = [];
            applicationStatus.value = undefined;
        };

        const tableHistoryData = ref<any[]>([]);
        const pagination = reactive({
            total: 0,
            currentPage: 1,
            pageSize: 20,
        });

        const exportHistoryResult = () => {
            const params = {
                url: '/claimapi/dealer/claim/detail/exportHistory',
                method: 'post',
                params: {
                    appealStatus: appealStatus.value, // 申诉状态
                    queryDealerTrade: queryDealerTrade.value,
                    bus: buList.value.join(','),
                    checkResult: checkResult.value, // 检查结果
                    claimDateStart:
                        (submissionDate.value[0] &&
                            submissionDate.value[0].format('yyyy-MM-DD')) ||
                        '',
                    claimDateEnd:
                        (submissionDate.value[1] &&
                            submissionDate.value[1].format('yyyy-MM-DD')) ||
                        '',
                    communicationStatus: communicationStatus.value, // 沟通状态
                    confirmDeadline: [],
                    confirmDeadlineStart:
                        (communicationDeadline.value[0] &&
                            communicationDeadline.value[0].format(
                                'yyyy-MM-DD'
                            )) ||
                        '',
                    confirmDeadlineEnd:
                        (communicationDeadline.value[1] &&
                            communicationDeadline.value[1].format(
                                'yyyy-MM-DD'
                            )) ||
                        '',
                    dealerCode: dealerCode.value,
                    modelName: model.value,
                    modelYear: modelYear.value, // 年款
                    programCode: programCode.value,
                    programNameCn: programName.value,
                    vin: vinNos.value.join(','),
                    applicationStatus: applicationStatus.value,
                },
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8');
        };

        //? 页面初始化相关
        const getCommunicationHistoryData = () => {
            const len = vinNos.value.length;
            const params = {
                current: pagination.currentPage - 1,
                size: pagination.pageSize,
                bus: buList.value.join(','),
                dealerCode: dealerCode.value,
                vin: len === 0 ? vinNo.value : '',
                vins: len !== 0 ? vinNos.value : '',
                modelName: model.value,
                modelYear: modelYear.value, // 年款
                claimDateStart:
                    (submissionDate.value[0] &&
                        submissionDate.value[0].format('yyyy-MM-DD')) ||
                    '',
                claimDateEnd:
                    (submissionDate.value[1] &&
                        submissionDate.value[1].format('yyyy-MM-DD')) ||
                    '',
                confirmDeadlineStart:
                    (communicationDeadline.value[0] &&
                        communicationDeadline.value[0].format('yyyy-MM-DD')) ||
                    '',
                confirmDeadlineEnd:
                    (communicationDeadline.value[1] &&
                        communicationDeadline.value[1].format('yyyy-MM-DD')) ||
                    '',
                programCode: programCode.value,
                programNameCn: programName.value,
                checkResult: checkResult.value, // 检查结果
                communicationStatus: communicationStatus.value, // 沟通状态
                appealStatus: appealStatus.value, // 申诉状态
                queryDealerTrade: queryDealerTrade.value,
                applicationStatus: applicationStatus.value,
            };
            window.sessionStorage.setItem(
                'historySearchCondition',
                JSON.stringify(params)
            );
            communicationHistoryData(params).then((res: any) => {
                tableHistoryData.value = res.data;
                pagination.total = res.totalNum;
            });
        };

        const search = () => {
            pagination.currentPage = 1;
            getCommunicationHistoryData();
        };

        const pageChange = (page: number) => {
            pagination.currentPage = page;
            getCommunicationHistoryData();
        };

        const sizeChange = (page: number, pageSize: number) => {
            pagination.pageSize = pageSize;
            pagination.currentPage = page;
            getCommunicationHistoryData();
        };

        onBeforeRouteLeave((to) => {
            if (to.fullPath !== '/communication/appeal/detail') {
                window.sessionStorage.removeItem('historySearchCondition');
            }
        });

        onMounted(() => {
            getApplicationStatus();
            init(getCommunicationHistoryData);
        });

        return {
            tableHistoryData,
            spaceSize,
            batchPlaceholder,
            textContentNo,
            input,
            clearBatchQuery,
            ...toRefs(batchQuery),
            batchQueryEvent,
            textNoArray,
            closeBatchQueryModal,
            textChangEvent,
            vinNo,
            vinNos,
            model,
            modelYear,
            submissionDate,
            programCode,
            programName,
            checkResult,
            checkResultList,
            communicationStatus,
            communicationStatusList,
            appealStatus,
            queryDealerTrade,
            appealStatusList,
            modelYearList,
            communicationDeadline,
            rangePickerCheck,
            applicationStatus,
            applicationStatusList,
            ...toRefs(pagination),
            search,
            pageChange,
            sizeChange,
            historyReset,
            exportHistoryResult,
        };
    },
});
